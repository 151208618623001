// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Colors
\* ------------------------------------------------------------------------ */

$primary: #004C9F;
$primary_alt: #071B3D;

$accent: #E8001C;
$accent_alt: #FF001E;

$light: #FFFFFF;
$light_alt: #D4D4D4;

$dark: #000000;
$dark_alt: #3C3C3C;

$foreground: #303030;
$foreground_alt: #000000;

$background: #FFFFFF;
$background_alt: #EEEEEE;

$page_background: #071B3D;
$page_background_alt: #004C9F;

$warning: #9F0000;
$warning_alt: #F83636;

/* ------------------------------------------------------------------------ *\
 * Fonts                                                                    *
\* ------------------------------------------------------------------------ */

$heading-font: "eurostile", "proxima-nova", "Arial", "Helvetica", sans-serif;
$body-font: "proxima-nova", "Arial", "Helvetica", sans-serif;

/* ------------------------------------------------------------------------ *\
 * Widths                                                                   *
\* ------------------------------------------------------------------------ */

$site-width: 1400;

/* ------------------------------------------------------------------------ *\
 * Break Points                                                             *
\* ------------------------------------------------------------------------ */

$xxxl-break: 1366em / 16;
$xxl-break: 1280em / 16;
$xl-break: 1024em / 16;
$l-break: 960em / 16;
$m-break: 853em / 16;
$s-break: 768em / 16;
$xs-break: 640em / 16;
$xxs-break: 480em / 16;

/* ------------------------------------------------------------------------ *\
 * Layers                                                                   *
\* ------------------------------------------------------------------------ */

$layers: (
    header: 5,
    navigation: 4,
    hero: 3,
    content: 2,
    footer: 1,
);
