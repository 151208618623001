// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Header
\* ------------------------------------------------------------------------ */

.header_logo {
    & {
        padding-bottom: 0;
        padding-top: 0;
    }

    &:before {
        top: remify(15);
    }
}
