// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Swiper (http://idangero.us/swiper/)
\* ------------------------------------------------------------------------ */

// Custom

.swiper-container {
    & {
        padding: 0;
    }
}

.swiper-slide {
    & {
        padding-left: 40%;
    }
}

.swiper-caption {
    & {
        left: 0;
        padding: remify(40, 16);
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        width: 40%;
        z-index: 2;
    }
}

.swiper-title {
    & {
        font-size: remify(31, 16);
    }
}

.swiper-divider {
    & {
        margin-bottom: remify(25, 16);
    }
}

.swiper-logo {
    &.-partner {
        font-size: remify(20, 16);
        left: auto;
        margin-bottom: remify(35, 20);
        position: static;
        top: auto;
    }

    &.-partner .logo_image {
        margin-top: remify(-8, 20);
    }
}
