// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Hero
\* ------------------------------------------------------------------------ */

// headline variant

.hero-block.-headline {
    &.-fullbleed {
        background: linear-gradient(to bottom, lighten($dark, 26.6667) 20%, lighten($dark, 18.8235) 80%, mix(lighten($dark, 18.8235), lighten($dark, 12.5490)) 100%);
    }

    .hero_inner {
        background: linear-gradient(to bottom, lighten($dark, 26.6667) 20%, lighten($dark, 18.8235) 80%, mix(lighten($dark, 18.8235), lighten($dark, 12.5490)) 100%);
        padding: remify(50 55);
    }

    &.-fullbleed > .hero_inner {
        background: none;
    }
}
