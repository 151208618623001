// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Content
\* ------------------------------------------------------------------------ */

.content-block {
    & {
        @include layer("content");
        background: darken($background, 20);
        background: repeating-linear-gradient(to bottom, transparentize(darken($background, 20), 1), transparentize(darken($background, 20), 1) calc(100% - #{remify(1)}), darken($background, 25.8824) calc(100% - #{remify(1)}), darken($background, 25.8824) 100%), repeating-linear-gradient(to right, darken($background, 20), darken($background, 20) calc(100% - #{remify(1)}), darken($background, 25.8824) calc(100% - #{remify(1)}), darken($background, 25.8824) 100%);
        background-size: remify(8 8);
        padding: remify(50 0);
    }

    &.-fullbleed {
        padding: remify(55);
    }

    .hero-block.-headline + & {
        background: linear-gradient(to bottom, mix(lighten($dark, 18.8235), lighten($dark, 12.5490)) 0%, lighten($dark, 3.1373) 80%), repeating-linear-gradient(to bottom, transparentize(darken($background, 20), 1), transparentize(darken($background, 20), 1) calc(100% - #{remify(1)}), darken($background, 25.8824) calc(100% - #{remify(1)}), darken($background, 25.8824) 100%), repeating-linear-gradient(to right, darken($background, 20), darken($background, 20) calc(100% - #{remify(1)}), darken($background, 25.8824) calc(100% - #{remify(1)}), darken($background, 25.8824) 100%);
        background-size: 100% remify(120), remify(8 8), remify(8 8);
        background-repeat: no-repeat, repeat, repeat;
        padding-top: 0;
    }
}

.content_inner {
    & {
        box-shadow: 0px 1px 2.94px 0.06px rgba(0, 0, 0, 0.68);
    }

    .content-block.-fullbleed > & {
        box-shadow: none;
    }

    // featuerdimage variant

    &.-featuredimage {
        background-position: right remify(-40) top remify(40);
        background-repeat: no-repeat;
        background-size: 47.857142857142857% auto;
    }

    &.-featuredimage > .content_post {
        padding-right: 46.124031007751938%;
    }

    // clean variant

    &.-clean {
        box-shadow: none;
        padding-bottom: remify(38);
        padding-top: remify(20);
    }
}

.content_post {
    & {
        padding: remify(55);
    }
}

.content_sidebar {
    & {
        padding: remify(55 35);
        width: remify(350);
    }
}
