// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Header
\* ------------------------------------------------------------------------ */

.header-block {
    &.-fullbleed {
        background: darken($background, 12.9412);
        padding: remify(15);
    }
}

.header_inner {
    & {
        background: darken($background, 12.9412);
        padding: remify(15);
    }

    .header-block.-fullbleed > & {
        padding: 0;
    }
}

.header_logo {
    & {
        margin: 0;
        max-width: remify(290);
        padding: remify(5 0);
        position: relative;
    }

    &:before {
        background: linear-gradient(to right, darken($background, 12.9412) 0%, darken($background, 1.1765) 20%, darken($background, 0.3922) 50%, darken($background, 1.1765) 80%, darken($background, 12.9412) 100%);
        bottom: remify(-15);
        content: "\0020";
        left: 0;
        position: absolute;
        transform: translateX(-25%);
        top: remify(-15);
        width: 200%;
        z-index: -1;
    }

    .logo_image {
        margin-top: remify(-30);
    }
}

.header_menu-list_container .menu-list.-navigation {
    & {
        margin-right: remify(-34, 22); /* not a fan of magic numbers, but whatever... */
    }

    @media screen and (max-width: $xxxl-break) {
        font-size: 1.610541727672035vw;
    }
}
