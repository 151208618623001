// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Widget
\* ------------------------------------------------------------------------ */

.widget {
    & {
        box-shadow: remify(0 3 4.9 0.1, 16) transparentize($dark, 0.55);
        margin-bottom: remify(36, 16);
    }
}

.widget_title {
    & {
        font-size: remify(20, 16);
    }
}
