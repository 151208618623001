// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Footer
\* ------------------------------------------------------------------------ */

.footer_text {
    & {
        margin-bottom: 0;
        text-align: left;
    }
}

.footer_logo {
    &.-partner {
        font-size: remify(14, 16);
    }

    &.-partner .logo_image {
        margin-top: remify(-7, 14);
    }
}
