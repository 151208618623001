// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Map
\* ------------------------------------------------------------------------ */

.map {
    & {
        padding: 0;
    }

    @media screen and (max-width: remify($site-width, 16)) {
        font-size: 1.142857142857143vw;
    }
}

.map_infobox {
    & {
        height: remify(181, 16);
        margin: 0;
        overflow: auto;
        padding: remify(20, 16);
        position: absolute;
        width: remify(281, 16);
    }

    &.-location1 {
        left: 15.503875968992248%; // 200px @ 1290px
        position: absolute;
        top: remify(57, 16);
    }

    &.-location2 {
        left: 63.565891472868217%; // 820px @ 1290px
        position: absolute;
        top: remify(36, 16);
    }
}

.map_title {
    & {
        font-size: remify(24, 16);
    }
}

.map_text {
    & {
        font-size: remify(20, 16);
    }
}
