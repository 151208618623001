// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Toolbar
\* ------------------------------------------------------------------------ */

.toolbar-block {
    &.-fullbleed {
        padding: remify(15);
    }
}

.toolbar_inner {
    & {
        padding: remify(15);
    }
}

@media screen and (max-width: $xxxl-break) {
    .toolbar_title {
        font-size: 1.6800878477306vw;
    }
}
